import React from 'react';

const RegularFlow = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="26.859"
    height="42.25"
    viewBox="0 0 26.859 42.25"
  >
    <defs>
      <clipPath>
        <rect width="26.859" height="42.25" fill="currentColor" />
      </clipPath>
    </defs>
    <g clipPath="url(#clip-path)">
      <path
        d="M13.469,42.25a13.754,13.754,0,0,1-9.723-4.2,13.113,13.113,0,0,1-3.74-9.524,20.977,20.977,0,0,1,1.871-7.1A161.838,161.838,0,0,1,12.092.966,1.664,1.664,0,0,1,13.47,0a1.473,1.473,0,0,1,1.259.859C19.283,8.94,23.282,16.3,26.107,24.49a13.063,13.063,0,0,1-.922,10.78,13.472,13.472,0,0,1-8.671,6.539c-.682.15-1.382.235-2.059.317-.313.038-.626.076-.938.12l-.024,0ZM13.48,4.178c-.14.24-.248.426-.352.615A143.194,143.194,0,0,0,4.1,23.45a13.67,13.67,0,0,0-1.166,7.158,10.761,10.761,0,0,0,12.9,8.514,10.544,10.544,0,0,0,6.725-4.874A10.179,10.179,0,0,0,23.678,26.2,71.588,71.588,0,0,0,18.34,13.468c-1.119-2.225-2.269-4.395-3.487-6.692q-.68-1.283-1.374-2.6"
        transform="translate(0)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default RegularFlow;
